import { differenceInDays, differenceInYears, parse } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { formatCurrency, formatDateWithTimezone } from './index';
import { round } from 'lodash';

export const generateBookingGroupUuid = () => {
  return uuidv4();
};

export const calculateTravelerKind = traveler => {
  const birthdate = traveler?.birthdate;
  const date = parse(birthdate, 'yyyy-mm-dd', new Date());
  const age = differenceInYears(new Date(), date);
  if (age < 18) return 'child';
  else return 'adult';
};

export const calculateBookingTotalPriceWithPromo = ({ roomsBooked }) => {
  let totalPriceWithPromo = 0;
  roomsBooked.forEach(({ room }) => {
    totalPriceWithPromo += room?.bedChoice.totalPriceWithPromo;
  });

  return totalPriceWithPromo;
};

export const calculateBookingNumberOfNights = ({ startAt, endAt }) => {
  const startDate = parse(startAt, 'yyyy-mm-dd', new Date());
  const endDate = parse(endAt, 'yyyy-mm-dd', new Date());
  return differenceInDays(endDate, startDate);
};

export const calculateBookingTravelersCount = ({ roomsBooked, experiencesBooked }) => {
  let travelersCount = 0;
  (roomsBooked ?? []).forEach(({ travelers }) => {
    travelersCount += travelers.length;
  });

  (experiencesBooked ?? []).forEach(({ travelers }) => {
    travelersCount += travelers.length;
  });

  return travelersCount;
};

export const calculateRoomNumberOfAdult = ({ travelers }) => {
  return travelers?.filter(
    traveler => calculateTravelerKind(traveler) === 'adult',
  ).length;
};

export const getRoomChildrenBirthdate = ({ travelers }) => {
  return travelers
    ?.filter(traveler => calculateTravelerKind(traveler) !== 'adult')
    .map(children => children.birthdate);
};

export const bookingsTypeEnum  = {
  EXPERIENCE: 'experience',
  HOTEL: 'hotel',
  TRIP: 'trip',
  DRAFT: 'draft',
  HISTORY: 'history'
}

export const accordeonDetailsContractingConditions = (room, bedField, t, currency = null) => {
    return ([
      {
        description: { displayName: t('contractingConditions')},
        content: `${room[bedField].cancelConditions.map((cc)=>{
          return (`${t('room.from')}
          "${formatDateWithTimezone(cc.startAt)}" ${t('room.to')}${' '}
          "${formatDateWithTimezone(cc.endAt)}"${', '}
          ${t('room.cancellationFees')} ${"= "} ${formatCurrency({amount: round(cc.deduction, 2),currency: currency ?? room[bedField].currency})}<br><br>`)
        }).join('')}${!!room[bedField]?.contractingConditions ? room[bedField].contractingConditions : ""}`
      }
    ])
}

export const mergeCancelConditions = (rooms, bedField) => {
  const cancelConditionsMap = new Map();

  rooms.forEach(room => {
    room[bedField].cancelConditions.forEach(cc => {
      const periodKey = `${cc.startAt}-${cc.endAt}`;
      if (!cancelConditionsMap.has(periodKey)) {
        cancelConditionsMap.set(periodKey, { ...cc });
      } else {
        cancelConditionsMap.get(periodKey).deduction += cc.deduction;
      }
    });
  });

  return Array.from(cancelConditionsMap.values());
}
